<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon color="primary" size="80" class="animate__animated animate__rotateIn">mdi-information-outline</v-icon>
          <h2 class="text--primary mt-3">Supprimer un lien</h2>
          <p class="mt-5 mb-5"> Voulez-vous vraiment supprimer ce lien ?</p>
          <v-btn text class="rounded-lg mr-2 text-none" large @click="notConfirmed()">
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn class="rounded-lg text-none" large color="primary" depressed @click="confirm()" :loading="loading">
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { HTTP } from '@/http-common';
export default {
  data() {
    return {
      dialog: false,
      is_confirm: false,
      confirm_dialog: false,
      loading: false
    }
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true
    },
    confirm() {
      let id = this.item.id;
      this.loading = true;

      HTTP.delete("v1/apk_menu_items/" + id)
        .then(() => {
      this.loading = false;

          this.$successMessage = "Ce menu a été supprimée";
          this.$emit('refresh')
          this.dialog = false;
        })
        .catch((err) => {
      this.loading = false;

          console.log(err);
        });

    },
    notConfirmed() {
      this.dialog = false;
    }
  }
}
</script>