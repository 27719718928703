<template>
  <div>
    <p class="mb-4 title">Paramètres Easy Flexy</p>
    <v-card class="mb-3">
      <v-card-text>
        <form @submit.prevent="loginEasyFlexy">

          <!--  Url -->
          <v-text-field
            outlined
            prepend-inner-icon="mdi-link"
            type="text"
            v-model="formLogin.url"
            label="Eay Flexy Api Url (sans SLASH a la fin)"
            placeholder="https://detail.denni-telecom.me/api"
            hide-details=""
            :color="errorslogin && errorslogin.url ? 'red' : 'primary'"
            :class="errorslogin && errorslogin.url ? 'redBorder' : ''"
            @input="errorslogin && errorslogin.url ? (errorslogin.url = '') : false"
            :loading="loading"
          >
          </v-text-field>
          <p class="red--text">
            {{ errorslogin.url ? errorslogin.url[0] : "" }}
          </p>
          <!-- End Url -->

          <!--  phone -->
          <v-text-field
            outlined
            prepend-inner-icon="mdi-phone-outline"
            type="phone"
            v-model="formLogin.phone"
            label="Téléphone *"
            hide-details=""
            :color="errorslogin && errorslogin.phone ? 'red' : 'primary'"
            :class="errorslogin && errorslogin.phone ? 'redBorder' : ''"
            @input="
              errorslogin && errorslogin.phone
                ? (errorslogin.phone = '')
                : false
            "
          >
          </v-text-field>
          <p class="red--text">
            {{ errorslogin.phone ? errorslogin.phone[0] : "" }}
          </p>
          <!-- End phone -->

          <v-text-field
            outlined
            prepend-inner-icon="mdi-lock-outline"
            v-model="formLogin.password"
            :type="showLogin ? 'text' : 'password'"
            :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showLogin = !showLogin"
            label="Mot de passe *"
            :color="errorslogin && errorslogin.password ? 'red' : 'primary'"
            :class="errorslogin && errorslogin.password ? 'redBorder' : ''"
            hide-details=""
          >
          </v-text-field>
          <p class="red--text">
            {{ errorslogin.password ? errorslogin.password[0] : "" }}
          </p>

          <v-btn
            type="submit"
            depressed
            :loading="loginLoading"
            large
            block
            color="primary"
            class="rounded-lg mt-3 mb-5"
          >
            S'identifier
          </v-btn>

          <v-chip color="grey" text-color="white" class="font-weight-bold" v-if="token">
            <span class="inv-text" style="max-width: 90%; overflow: hidden;">{{  token }}</span>
            <v-divider class="mx-4" vertical light></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click="copyToken"
                  @mouseout="reset"
                  style="cursor: pointer"
                  v-bind="attrs"
                  v-on="on"
                  >Copy</span
                >
              </template>
              <span>{{ copyText }}</span>
            </v-tooltip>
          </v-chip>
        </form>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <form @submit.prevent="updateSettings">
          
          <!--  Url -->
          <v-text-field
            outlined
            prepend-inner-icon="mdi-link"
            type="text"
            v-model="form.url"
            label="Eay Flexy Api Url (sans SLASH)"
            placeholder="https://detail.denni-telecom.me/api"
            hide-details=""
            :color="errors && errors.url ? 'red' : 'primary'"
            :class="errors && errors.url ? 'redBorder' : ''"
            @input="errors && errors.url ? (errors.url = '') : false"
            :loading="loading"
          >
          </v-text-field>
          <p class="red--text">
            {{ errors.token ? errors.token[0] : "" }}
          </p>
          <!-- End Url -->

          <!--  Url -->
          <v-text-field
            outlined
            prepend-inner-icon="mdi-lock"
            type="text"
            v-model="form.token"
            label="Token"
            placeholder=""
            hide-details=""
            :color="errors && errors.token ? 'red' : 'primary'"
            :class="errors && errors.token ? 'redBorder' : ''"
            @input="errors && errors.token ? (errors.token = '') : false"
            :loading="loading"
          >
          </v-text-field>
          <p class="red--text">
            {{ errors.token ? errors.token[0] : "" }}
          </p>
          <!-- End Url -->

          <!--  phone -->
          <v-text-field
            outlined
            prepend-inner-icon="mdi-phone-outline"
            type="phone"
            v-model="form.phone"
            label="Téléphone *"
            hide-details=""
            :color="errors && errors.phone ? 'red' : 'primary'"
            :class="errors && errors.phone ? 'redBorder' : ''"
            @input="
              errors && errors.phone
                ? (errors.phone = '')
                : false
            "
            disabled
          >
          </v-text-field>
          <p class="red--text">
            {{ errors.phone ? errors.phone[0] : "" }}
          </p>
          <!-- End phone -->

          <v-text-field
            outlined
            prepend-inner-icon="mdi-lock-outline"
            v-model="form.password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            label="Mot de passe *"
            :color="errors && errors.password ? 'red' : 'primary'"
            :class="errors && errors.password ? 'redBorder' : ''"
            hide-details=""
            
          >
          </v-text-field>
          <p class="red--text">
            {{ errors.password ? errors.password[0] : "" }}
          </p>

          <v-btn
            type="submit"
            depressed
            :loading="formLoading"
            large
            block
            color="primary"
            class="rounded-lg mt-3 mb-5"
          >
            Enregister
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      show: false,
      showLogin: false,
      loading: false,
      // save url
      formLogin: {
        url: "",
        phone: "",
        password: "",
      },
      loginError: "",

      errorslogin: {},
      loginLoading: false,

      form: {
        phone: "",
        password: "",
        url: "",
        token: "",
      },
      formLoading: false,
      errors: {},
      token: "",

      copyText: "",
    };
  },

  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("/v1/settings/show/easy_flexy")
        .then((res) => {
          console.log(res);
          this.loading = false;

          var settings = res.data.data.data;

          this.form = settings;

          this.formLogin.password = this.form.password;
          this.formLogin.phone = this.form.phone;
        }).catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    updateSettings() {
      this.formLoading = true;
      HTTP.post("/v1/settings/updateEasyFlexySettings", this.form)
        .then((res) => {
          this.formLoading = false;

          this.$successMessage = "Mise a jour avec succès"
        })
        .catch((err) => {
          this.formLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errors = err.response.data.errors;
          }

          this.$errorMessage =
            "Une erreur s'est produite veuillez verifier votre formulaire.";
        });
    },

    loginEasyFlexy() {
      this.loginLoading = true;
      this.loginError = "";
      this.errorslogin = {};
      this.token = "";

      HTTP.post("/v1/settings/loginEasyFlexy", this.formLogin)
        .then((res) => {
          this.loginLoading = false;
          this.$successMessage = "Authentification avec succès";

          this.token = res.data.data.token;

          this.form.password = this.formLogin.password;
          this.form.phone = this.formLogin.phone;
          this.form.url = this.formLogin.url;
        }).catch((err) => {
          this.loginLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.errorslogin = err.response.data.errors;
          }

          this.$errorMessage =
            "Une erreur s'est produite veuillez verifier votre formulaire.";
        });
    },

    async copyToken() {
      await navigator.clipboard.writeText(this.token);
      this.copyText = "Copied";
    },
    reset() {
      this.copyText = "Copy";
    },
  },
};
</script>

<style  scoped>
.redBorder >>> fieldset {
  border-color: rgba(250, 0, 33, 0.986);
}
</style>