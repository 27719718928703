<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="btnLoading" :disabled="btnLoading">
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-text-field
              label="Téléphone *"
              :error-messages="errors.phone"
              v-model="form.phone"

              outlined
            />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="btnLoading"
              depressed
              type="submit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Envoyer
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";
var defaultForm = {
  phone: "",
  device_id: "",
};
export default {
  data() {
    return {
      title: null,
      dialog: false,
      btnLoading: false,

      errors: {},
      form: defaultForm,

      item: null,
    };
  },
  methods: {
    open(device) {
      this.errors = {};

      this.title = "Envoyer un SMS";
      this.form = defaultForm;

      this.form.device_id = device.id;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.errors = {};
      this.isLoading = true;
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] == "boolean") {
          formData.append(key, this.form[key] ? 1 : 0);
        } else {
          formData.append(key, this.form[key]);
        }
      });
      var url = "v1/devices/testSendSms";

      HTTP.post(url, formData)
        .then(() => {
          this.isLoading = false;
          this.btnLoading = false;
          this.$successMessage = "Sms Envoye.";
          this.close();
          this.$emit("refresh");

          this.$refs.file.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
          this.isLoading = false;
          this.btnLoading = false;
          console.log(err);
        });
    },
  },
};
</script>