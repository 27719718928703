<template>
  <div>
    <p class="mb-4 title">Paiement par carte (CIB)</p>
    <v-card
        class="rounded-lg shadow"
        :loading="isLoading"
        :disabled="isLoading"
        elevation="0"
    >
      <v-card-text class="py-5 px-5">

        <v-switch hide-details
                  class="ma-0 pa-0 mb-5"
                  v-model="epayment_active"
                  color="blue"
                  label="Activation de Paiement par carte"
        ></v-switch>

        <v-row>
          <v-col cols="6">
            <v-text-field type="number"
                          outlined
                          v-model="min_amount"
                          label="Le montant minimum *"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number"
                          outlined
                          v-model="max_amount"
                          label="Le montant maximum *"></v-text-field>
          </v-col>
        </v-row>


        <v-btn block color="primary" large depressed class="rounded-lg" @click="changeEpaymentStatus">
          Enregistrer
        </v-btn>

      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  components: {},
  data() {
    return {
      isLoading: true,
      errors: {},
      epayment_active: false,
      min_amount: null,
      max_amount: null,
    };
  },
  methods: {
    fetchData() {
      console.log("Flexy");

      this.isLoading = true;
      HTTP.get("/v1/settings")
          .then((res) => {
            this.epayment_active = res.data.data.epayment.enabled;
            this.min_amount = res.data.data.epayment.min_amount;
            this.max_amount = res.data.data.epayment.max_amount;
            this.isLoading = false;

          })
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
          });
    },
    changeEpaymentStatus() {
      this.isLoading = true;
      HTTP.post("/v1/settings/epayment_active", {
        e_payment : {
          enabled: this.epayment_active,
          min_amount: parseInt(this.min_amount),
          max_amount: parseInt(this.max_amount)
        }
      }).then(() => {
        this.isLoading = false;
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>