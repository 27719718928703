<template>
  <div>
    <p class="mb-4 title">Service SMS</p>
    <v-card
      class="rounded-lg shadow"
      :loading="isLoading"
      :disabled="isLoading"
      elevation="0"
    >
      <v-card-text class="py-5 px-5">
        <v-select
          label="Service Par defaut"
          :items="smsSerivces"
          item-value="value"
          item-text="label"
          :error-messages="errors.default_sms_service"
          v-model="form.default_sms_service"
          outlined
          @change="serviceChanged()"
        />

        <div v-if="form.default_sms_service == 'hybrid_sms'">

          <v-row>
            <v-col>
                <h4 class="mb-4">Ordre des services</h4>
              <v-simple-table elevation="0">
                <template v-slot:default>
                  <draggable
                    tag="tbody"
                    v-model="sms_orders"
                    :sort="true"
                    @change="orderChanged"
                    :options="{ group: 'services' }"
                  >
                    <tr v-for="item in sms_orders" :key="item">
                      <td>
                        {{ item }}
                      </td>
                      <td class="font-weight-bold">
                        <span class="handle font-weight-black">&#8597;</span>
                      </td>
                      <td>
                        <v-btn icon class="red" @click="deleteOrderService(item)">
                            <v-icon class="white--text">mdi-delete-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-col>

            <v-col>
                <h4 class="mb-4">Autres services</h4>
              <v-simple-table elevation="0">
                <template v-slot:default>
                  <draggable
                    tag="tbody"
                    v-model="otherSerives"
                    :options="{ group: 'services' }"
                  >
                    <tr v-for="item in otherSerives" :key="item">
                      <td>
                        {{ item }}
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { HTTP } from "@/http-common";
import draggable from "vuedraggable";
export default {
  components: { draggable },
  data() {
    return {
      isLoading: true,
      form: {
        default_sms_service: ""
      },
      smsSerivces: [],
      errors: {},
      sms_orders: [],
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/settings")
        .then((res) => {
          this.isLoading = false;
          this.form.default_sms_service = res.data.data.SMS_SERVICE;
          this.form.sms_orders =
            res.data.data.HYBRID_SMS_SERVICES_ORDER.split(",");

          this.sms_orders = res.data.data.HYBRID_SMS_SERVICES_ORDER.split(",");

          this.smsSerivces = res.data.data.smsSerivces;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    orderChanged() {
      HTTP.post("/v1/settings/order_sms_services", {
        order: this.sms_orders.join(","),
      })
        .then((res) => {
          this.isLoading = false;
          this.$successMessage = "Ordre mis a jour";
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    serviceChanged() {
      HTTP.post("/v1/settings/default_sms_services", {
        service: this.form.default_sms_service,
      })
        .then((res) => {
          this.isLoading = false;
          this.$successMessage = "Service par defaut mis a jour";
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    deleteOrderService(service){
        const index = this.sms_orders.indexOf(service);
        if (index > -1) { // only splice array when item is found
            this.sms_orders.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.serviceChanged();
    }
    
  },
  computed: {
    otherSerives(){
        var result = [];
        this.smsSerivces.forEach(element => {
            if(this.sms_orders.includes(element.value) == false){
                result.push(element.value);
            }
        });
        return result;
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>