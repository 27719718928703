<template>
    <div>
      <p class="mb-4 title">Appareils SMS</p>
      <v-card
        class="rounded-lg shadow"
        :loading="isLoading"
        :disabled="isLoading"
        elevation="0"
      >
        <v-card-text class="p-3">
          <v-skeleton-loader v-if="isLoading" type="table" />
  
          <div v-else>
            <div class="d-flex justify-space-between mb-2">
              <div></div>
              <div class="d-flex justify-space-between">
                <v-text-field
                  v-model="filter.keyword"
                  rounded
                  filled
                  dense
                  style="width: 300px"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  clearable
                  placeholder="Rechercher..."
                  v-on:keyup.enter="getItems"
                />
  
                <v-btn
                  rounded
                  color="primary"
                  class="ml-1"
                  elevation="0"
                  @click="addDevice"
                  >Ajouter</v-btn
                >
              </div>
            </div>
  
            <v-divider />
  
            <v-simple-table elevation="0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Titre</th>
                    <th>Telephone</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in devices" :key="item.id">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.phone }}
                    </td>
                    <td>
                      <v-menu transition="slide-x-transition" bottom right>
                        <v-list dense>
                          <v-list-item @click="testSendSms(item)">
                            <v-list-item-icon>
                              <v-icon color="black">mdi-send</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Tester</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="updateDevice(item)">
                            <v-list-item-icon>
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Modifier</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteDevice(item)">
                            <v-list-item-icon>
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Supprimer</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
      <FormDevice ref="formDevice" @refresh="getItems" />
      <DeleteDialog ref="deleteDevice" @confirmed="removeDevice" />
      <TestSendSms ref="testSendSms" />
    </div>
  </template>
  
  <script>
  import FormDevice from "./components/FormDevice.vue";
  import DeleteDialog from "./components/DeleteDialog.vue";
  import TestSendSms from "./components/TestSendSms.vue";
  import { HTTP } from "@/http-common";
  
  export default {
    components: { FormDevice, DeleteDialog, TestSendSms },
    data() {
      return {
        delete_id: null,
        filter: {},
        isLoading: false,
        devices: [],
      };
    },
    methods: {
      getItems() {
        this.isLoading = true;
  
        HTTP.get("v1/settings/devices")
          .then((res) => {
            this.devices = res.data.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
      },
      addDevice() {
        this.$refs.formDevice.open();
      },
      updateDevice(item) {
        this.$refs.formDevice.open(item);
      },
      deleteDevice(item) {
        this.delete_id = item.id;
        this.$refs.deleteDevice.open();
      },
      removeDevice() {
        HTTP.delete("v1/settings/devices/" + this.delete_id)
          .then(() => {
            this.$successMessage = "Cet Appareil a été supprimé";
            this.getItems();
          })
          .catch((err) => {
            console.log(err);
          });
      },

      testSendSms(device){
        this.$refs.testSendSms.open(device);
      }
    },
  };
  </script>