<template>
    <div>
        <v-dialog v-model="openDialog" max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Nouveau lien Menu
                    <v-spacer />
                    <v-btn icon class="v-btn--active" color="primary" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-6">
                    <form @submit.prevent="save">


                        <v-text-field label="Titre *" @input="
                            formErrors && formErrors.title
                                ? (formErrors.title = '')
                                : false
                            " :error-messages="formErrors && formErrors.title ? formErrors.title[0] : ''
        " v-model="form.title" />


                        <v-text-field label="Titre en arabe *" @input="
                            formErrors && formErrors.title_ar
                                ? (formErrors.title_ar = '')
                                : false
                            " :error-messages="formErrors && formErrors.title_ar
        ? formErrors.title_ar[0]
        : '' " v-model="form.title_ar" />

<v-text-field label="Action *" @input="
                            formErrors && formErrors.action
                                ? (formErrors.action = '')
                                : false
                            " :error-messages="formErrors && formErrors.action ? formErrors.action[0] : ''
        " v-model="form.action" />



                        <div class="d-flex align-center">
                            <v-file-input label="Icone *" accept="image/png, image/jpeg, image/jpg, .svg, .gif" 
                                show-size
                                suffix="Image" @change="uploadFile" @focus="
                                formErrors && formErrors.icon
                                        ? (formErrors.icon = '')
                                        : false
                                    " ref="fileIcon" chips prepend-icon="mdi-camera"
                                :error-messages="formErrors.icon" />

                            <v-btn depressed icon small class="primary white--text ml-2" v-if="form.icon"
                                @click="previewImage(form.icon)">
                                <v-icon small>mdi mdi-eye</v-icon>
                            </v-btn>
                        </div>

                        <v-btn color="primary" class="rounded-lg text-none mt-3" :loading="btnLoading" large depressed
                            @click="save">
                            <v-icon left>mdi-send-outline</v-icon>
                            Savegarder
                        </v-btn>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { HTTP } from "@/http-common";

export default {
    data() {
        return {
            btnLoading: false,

            formErrors: {},
            form: {
                name: "",
                name_ar: "",
                description: "",
                description_ar: "",
                icon: "",
                order: 0,
            },
            openDialog: false,
            language_tab: null,
            item: null
        };
    },

    computed: {},
    methods: {
        open(item) {
            this.item = item;
            if (this.$refs.fileIcon) {
                this.$refs.fileIcon.reset();
            }

            if(item){
                this.form.title = item.title;
                this.form.title_ar = item.title_ar;
                this.form.action = item.action;
            }
            this.openDialog = true;
        },
        close() {
            //this.$emit("update:openDialog", false);
            this.openDialog = false;
            
        },
        save() {
            this.btnLoading = true;

            let formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key]);
            });
            var url = "v1/apk_menu_items";
            if(this.item){
                url+= "/"+this.item.id;
                formData.append("_method", 'PUT');
            }
            HTTP.post(url, formData, { params: {} })
                .then(() => {
                    this.$successMessage = "Enregistrement avec succès";
                    this.btnLoading = false;
                    this.close();
                    this.form = {};
                    this.$refs.fileIcon.reset();

                    this.openDialog = false;
                    this.$emit("refresh");
                })
                .catch((err) => {
                    this.btnLoading = false;
                    if(err.response && err.response.data && err.response.data.errors){
                        this.formErrors = err.response.data.errors;
                    }
                    
                });
        },

        uploadFile(file) {
            this.form.icon = file;
        },
    },
    created() { },
};
</script>
  
<style scoped>
.arabic .v-text-field__slot .v-label {
    text-align: right !important;
    direction: rtl;
    left: inherit;
    right: 0 !important;
}
</style>
  