<template>
  <div>
    <p class="mb-4 title">API Carte (Idoom, 4G)</p>
    <v-card
      class="rounded-lg shadow"
      :loading="isLoading"
      :disabled="isLoading"
      elevation="0"
    >
      <v-card-text class="py-5 px-5">
        <v-switch
          hide-details
          class="ma-0 pa-0 mb-5"
          :input-value="auto_mode_active"
          readonly
          @click="changeAutoModeStatus()"
          color="blue"
          label="Cartes avec mode auto"
        ></v-switch>

        <v-select
          label="Service Par defaut"
          :items="cardServices"
          item-value="value"
          item-text="label"
          :error-messages="errors.default_card_service"
          v-model="form.default_card_service"
          outlined
          @change="serviceChanged()"
        />

        <div v-if="form.default_card_service == 'hybrid_card'">
          <v-row>
            <v-col>
              <h4 class="mb-4">Ordre des services</h4>
              <v-simple-table elevation="0">
                <template v-slot:default>
                  <draggable
                    tag="tbody"
                    v-model="card_orders"
                    :sort="true"
                    @change="orderChanged"
                    :options="{ group: 'services' }"
                  >
                    <tr v-for="item in card_orders" :key="item">
                      <td>
                        {{ item }}
                      </td>
                      <td class="font-weight-bold">
                        <span class="handle font-weight-black">&#8597;</span>
                      </td>
                      <td>
                        <v-btn
                          icon
                          class="red"
                          @click="deleteOrderService(item)"
                        >
                          <v-icon class="white--text"
                            >mdi-delete-outline</v-icon
                          >
                        </v-btn>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-col>

            <v-col>
              <h4 class="mb-4">Autres services</h4>
              <v-simple-table elevation="0">
                <template v-slot:default>
                  <draggable
                    tag="tbody"
                    v-model="otherSerives"
                    :options="{ group: 'services' }"
                  >
                    <tr v-for="item in otherSerives" :key="item">
                      <td>
                        {{ item }}
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { HTTP } from "@/http-common";
import draggable from "vuedraggable";
export default {
  components: { draggable },
  data() {
    return {
      isLoading: true,
      form: {
        default_card_service: "",
      },
      cardServices: [],
      errors: {},
      card_orders: [],
      auto_mode_active: false
    };
  },
  methods: {
    fetchData() {
      console.log("Flexy");

      this.isLoading = true;
      HTTP.get("/v1/settings")
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.form.default_card_service = res.data.data.CARD_SERVICE;
          this.form.card_orders =
            res.data.data.HYBRID_CARD_SERVICES_ORDER.split(",");

          this.card_orders =
            res.data.data.HYBRID_CARD_SERVICES_ORDER.split(",");

          this.cardServices = res.data.data.cardServices;

          this.auto_mode_active = res.data.data.auto_mode_enabled;
          
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    orderChanged() {
      HTTP.post("/v1/settings/order_card_services", {
        order: this.card_orders.join(","),
      })
        .then((res) => {
          this.isLoading = false;
          this.$successMessage = "Ordre mis a jour";
        }).catch((e) => {
          this.isLoading = false;
        });
    },
    serviceChanged() {
      HTTP.post("/v1/settings/default_card_services", {
        service: this.form.default_card_service,
      })
        .then((res) => {
          this.isLoading = false;
          this.$successMessage = "Service par defaut mis a jour";
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    deleteOrderService(service) {
      const index = this.card_orders.indexOf(service);
      if (index > -1) {
        // only splice array when item is found
        this.card_orders.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.serviceChanged();
    },
    changeAutoModeStatus(){
      this.auto_mode_active = !this.auto_mode_active;

      HTTP.post("/v1/settings/cards_with_auto_mode", {
        active: this.auto_mode_active
      }).then((res) => {
          this.isLoading = false;
          this.$successMessage = "Service par defaut mis a jour";
        }).catch((e) => {
          this.isLoading = false;
        });
    }
  },
  computed: {
    otherSerives() {
      var result = [];
      this.cardServices.forEach((element) => {
        if (this.card_orders.includes(element.value) == false) {
          result.push(element.value);
        }
      });
      return result;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>