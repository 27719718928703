<template>
  <div>
    <p class="mb-4 title">Cashback</p>
    <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading">
      <v-card-text class="py-5 px-5">

        <v-text-field outlined label="Montant minimum de cashback" v-model="cashback_amount"></v-text-field>


        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveData">
          Enregistrer
        </v-btn>

      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      cashback_amount: null,
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/settings")
          .then((res) => {
            this.cashback_amount = res.data.data.cashback_amount;
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e)
            this.isLoading = false;
          });
    },
    saveData() {
      this.isLoading = true;
      HTTP.post("/v1/settings/update-cashback", {
        cashback_amount: parseInt(this.cashback_amount),
      }).then(() => {
        this.isLoading = false;
        this.$successMessage = "Le cashback a été mis à jour";
      }).catch(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>