<template>
  <div>

    <p class="mb-4 title">Environnement</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-simple-table class="table-border rounded-lg my-5">
          <template v-slot:default>
            <thead>
            <tr>
              <th width="40%">Clé</th>
              <th width="40%">Valeur</th>
              <th width="10%"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,i) in env" :key="i">

              <td>
                <v-text-field dense
                              hide-details="auto"
                              outlined
                              v-model="item.key"
                              label="Clé"></v-text-field>
              </td>

              <td>
                <v-text-field dense
                              hide-details="auto"
                              outlined
                              v-model="item.value"
                              label="Valeur"></v-text-field>
              </td>

              <td>
                <v-btn icon color="red" @click="env.splice(i,1)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>

            </tr>
            <tr v-if="!env.length">
              <td colspan="5" class="text-center">Aucun enregistrement trouvé!</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-center mb-5">
          <v-btn fab color="primary" depressed dark small @click="addItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      env: []
    };
  },
  methods: {
    addItem() {
      this.env.push({
        key: null,
        value: null
      })
    },
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-env", {
        env: this.env
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.env = res.data.data && res.data.data.env ? res.data.data.env : []
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>