<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="btnLoading" :disabled="btnLoading">
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider />

          <v-card-text class="pa-4">
            
            
            <v-row>
              <v-col>
                <v-text-field
                  label="Nom complet *"
                  :error-messages="errors.name"
                  v-model="form.name"
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="Téléphone *"
                  :error-messages="errors.phone"
                  v-model="form.phone"
                />
              </v-col>
            </v-row>

            

            <v-text-field
              label="Email *"
              :error-messages="errors.email"
              v-model="form.email"
            />

            <v-text-field
              label="Nom d'utilisateur *"
              :error-messages="errors.username"
              v-model="form.username"
            />

            <v-text-field
              label="Mot de pass *"
              :error-messages="errors.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              v-model="form.password"
            />

            <div class="d-flex align-center">
              <v-file-input
                label="Image *"
                accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                show-size
                @change="uploadFile"
                @focus="errors && errors.image ? (errors.image = '') : false"
                ref="file"
                chips
                prepend-icon="mdi-camera"
                :error-messages="errors.image"
              />
              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="form.image"
                @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <div
              v-if="user_picture"
              class="d-flex justify-space-between pa-3 mb-2 rounded"
              :class="
                $vuetify.theme.dark ? 'secondary lighten-1' : 'grey lighten-3'
              "
            >
              <v-avatar size="40">
                <v-img
                  :src="getImageLink(user_picture)"
                  class="rounded zoom-pointer"
                />
              </v-avatar>
              <v-spacer />
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

            <v-text-field
              label="Limte SMS*"
              :error-messages="errors.sms_limit"
              :type=" 'number'"
              v-model="form.sms_limit"
            />

            <v-switch v-if="item && item.fb_token"
              v-model="form.active"
              label="En Marche ?"
            ></v-switch>

          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="btnLoading"
              depressed
              type="submit"
            >
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import moment from "moment";
var defaultForm = {
        id: "",
        name: "",
        phone: "",
        email: "",
        username: "",
        password: "",
        image: "",
        sms_limit: 0,
        active: false
    };
export default {
  data() {
    return {
      title: null,
      dialog: false,
      isDBLoading: false,
      isRoleLoading: false,
      isCategoryLoading: false,
      isCountriesLoading: false,
      isWilayasLoading: false,
      btnLoading: false,
      show: false,

      dbErrors: {},
      menu: false,
      databaseName: null,
      addDBLoading: false,

      databases: [],
      roles: [],
      categories: [],
      countries: [],
      wilayas: [],

      errors: {},
      form: defaultForm,

      user_picture: "",
      
      dateMenu: false,
      item: null
    };
  },
  methods: {
    open(item) {
      this.item = item;
      if(this.$refs.file){
        this.$refs.file.reset();
      }
      this.errors = {};
      if (item) {
        this.title = "Modifier un appareil";

        this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          username: item.username,
          image: null,
          sms_limit: item.device.max_sms,
          active: item.device.active

        };

        if (item.dob) {
          const date = moment(item.dob, "DD-MM-YYYY");
          this.form.dob = date.format("YYYY-MM-DD");
        }

        this.user_picture = item.image;
      } else {
        this.title = "Ajouter un nouveau appareil";
        this.form = defaultForm;
      }

      this.dialog = true;

    
      
    },
    close() {
      this.$refs.file.reset();
      this.dialog = false;
    },
    save() {
      this.errors = {};
      this.isLoading = true;
      this.btnLoading = true;

      // if(this.form.active){
      //   this.form.active = 1;
      // }else{
      //   this.form.active = 0;
      // }

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        console.log(key);
        if( typeof this.form[key] == "boolean"){
          formData.append(key, this.form[key] ? 1 : 0);
        }else{
          formData.append(key, this.form[key]);
        }
        
      });
      var url = "v1/settings/devices";

      if(this.form.id){
        url += "/"+ this.form.id;
        formData.append("_method", "PUT");
      }

      HTTP.post(url, formData)
        .then(() => {
          this.isLoading = false;
          this.btnLoading = false;
          this.$successMessage = "Cet élément a été enregistré avec succès.";
          this.close();
          this.$emit("refresh");

          this.$refs.file.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
          this.isLoading = false;
          this.btnLoading = false;
          console.log(err);
        });
    },

    uploadFile(file) {
      this.form.image = file;
    },
  },
};
</script>