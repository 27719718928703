<template>
  <div>

    <p class="mb-4 title">Étapes page d'accueil</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">


      <v-overlay :value="overlay" opacity="0.6" color="white" absolute>
        <v-progress-circular color="gifty"
                             indeterminate
                             size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-card-text class="py-5 px-5">

        <v-row>
          <v-col cols="6">
            <v-text-field dense
                          hide-details="auto"
                          outlined
                          v-model="home_steps.title_fr"
                          label="Titre en FR"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field dense hide-details="auto"
                          outlined
                          style="direction: rtl"
                          v-model="home_steps.title_ar"
                          label="Titre en Ar"></v-text-field>
          </v-col>
        </v-row>

        <v-simple-table class="table-border rounded-lg my-5">
          <template v-slot:default>
            <thead>
            <tr>
              <th width="20%">Titre FR</th>
              <th width="20%">Titre AR</th>
              <th width="30%">Icon</th>
              <th width="25%">Url de video</th>
              <th width="5%"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(step,i) in home_steps.steps" :key="i">

              <td>
                <v-text-field dense
                              hide-details="auto"
                              outlined
                              v-model="step.title_fr"
                              label="Titre en FR"></v-text-field>
              </td>

              <td>
                <v-text-field dense
                              style="direction: rtl"
                              hide-details="auto"
                              outlined
                              v-model="step.title_ar"
                              label="Titre en AR"></v-text-field>
              </td>

              <td>
                <div class="d-flex align-center">
                  <v-file-input dense
                                @change="uploadIcon($event,i)"
                                hide-details="auto"
                                outlined
                                label="Icon"></v-file-input>
                  &nbsp;
                  <v-avatar rounded size="30">
                    <v-img :src="fileUrl + step.icon"></v-img>
                  </v-avatar>
                </div>
              </td>

              <td>
                <v-text-field dense
                              hide-details="auto"
                              outlined
                              v-model="step.video_link"
                              label="Url de video"></v-text-field>
              </td>

              <td>
                <v-btn icon color="red" @click="home_steps.steps.splice(i,1)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>

            </tr>
            <tr v-if="!home_steps.steps.length">
              <td colspan="5" class="text-center">Aucun enregistrement trouvé!</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-center mb-5">
          <v-btn fab color="primary" depressed dark small @click="addItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      overlay: false,
      fileUrl: process.env.VUE_APP_FILE_URL,
      isLoading: true,
      home_steps: {
        steps: []
      }
    };
  },
  methods: {
    uploadIcon(file, index) {
      if (file) {
        this.overlay = true
        let formData = new FormData()
        formData.append('file', file)
        HTTP.post("/v1/settings/upload-file", formData).then((res) => {
          this.overlay = false
          this.home_steps.steps[index].icon = res.data.data
        }).catch((e) => {
          this.overlay = false
          console.log(e)
        });
      }
    },
    addItem() {
      this.home_steps.steps.push({
        title_fr: null,
        title_ar: null,
        icon: null,
        video_link: null,
      })
    },
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/change-home_steps",{
        home_steps : this.home_steps
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.home_steps = res.data.data && res.data.data.home_steps ? res.data.data.home_steps : {steps: []}
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>