<template>
  <div>
    <p class="mb-4 title">Menu Application mobile</p>
    <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading" elevation="0">
      <v-card-text class="py-5 px-5">
        <v-switch hide-details class="ma-0 pa-0 mb-5" :input-value="apk_menu_active" readonly
          @click="changeSalepointMenuStatus()" color="blue" label="Activation de salepoint menu"></v-switch>

        <form @submit.prevent="saveLink">
          <!--  Url -->
          <v-text-field outlined prepend-inner-icon="mdi-link" type="text" v-model="form.menu_link" label="Menu Link url"
            placeholder="url ...." hide-details="" :color="formErrors && formErrors.menu_link ? 'red' : 'primary'"
            :class="formErrors && formErrors.menu_link ? 'redBorder' : ''"
            @input="formErrors && formErrors.menu_link ? (formErrors.menu_link = '') : false" :loading="isLoading">
          </v-text-field>
          <p class="red--text">
            {{ formErrors.menu_link ? formErrors.menu_link[0] : "" }}
          </p>
          <!-- End Url -->


          <v-btn type="submit" depressed :loading="btnLoading" large block color="primary" class="rounded-lg mt-3 mb-5">
            Enregister
          </v-btn>


          <h3 class="mb-5">Menu Gifty
            <v-btn icon class="primary" @click="addMenuItem">
              <v-icon class="white--text">mdi-plus</v-icon>
            </v-btn>
          </h3>

          <div v-if="menu_items.length > 0">
            <v-simple-table elevation="0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Icone</th>
                    <th>Titre</th>
                    <th>Titre Ar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in menu_items" :key="item.id">
                    <td>
                      {{ item.id }}
                    </td>
                    <td style="max-width: 50px">
                      <v-img :src="getImageLink(item.icon)" max-height="40" contain class="mt-2"
                        v-if="item.icon != null"></v-img>
                    </td>
                    <td>
                      {{ item.title }}
                    </td>
                    <td>
                      {{ item.title_ar }}
                    </td>
                    <td>
                      <v-menu transition="slide-x-transition" bottom right>
                        <v-list dense>
                          <v-list-item @click="updateMenuItem(item)">
                            <v-list-item-icon>
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Modifier</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteMenuItem(item)">
                            <v-list-item-icon>
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Supprimer</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div v-else>
            <div class="text-center">
              <v-avatar tile size="200">
                <v-img :src="require('@/assets/database.svg')"></v-img>
              </v-avatar>
              <h3 class="text--primary d-block mb-2">OOPS !</h3>
              <p>Aucun enregistrement correspondant trouvé.</p>
            </div>
          </div>
        </form>

        <h3 class="mb-4 mt-4">Design</h3>
        <form @submit.prevent="saveSettings" class="mt-5">
          <!--  success_animation -->
          <!-- <v-text-field outlined prepend-inner-icon="mdi-link" type="text" v-model="formSettings.success_animation" 
            label="Animation succès"
            placeholder="Animation succès ...." hide-details="" :color="formSettingsErrors && formSettingsErrors.success_animation ? 'red' : 'primary'"
            :class="formSettingsErrors && formSettingsErrors.success_animation ? 'redBorder' : ''"
            @input="formSettingsErrors && formSettingsErrors.success_animation ? (formSettingsErrors.success_animation = '') : false" :loading="isLoading">
          </v-text-field>
          <p class="red--text">
            {{ formSettingsErrors.success_animation ? formSettingsErrors.success_animation[0] : "" }}
          </p> -->
          <!-- End success_animation -->

          <div class="d-flex align-center">
              <v-file-input
                label="Animation succès .... *"
                accept="image/png, image/jpeg, image/jpg, .svg, .gif, .json"
                show-size
                suffix="Image"
                @change="$event =>  formSettings.success_animation = $event"
                @focus="
                  formSettingsErrors && formSettingsErrors.success_animation
                    ? (formSettingsErrors.success_animation = '')
                    : false
                "
                ref="success_animation_file"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formSettingsErrors.success_animation"
              />

              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="formSettings.success_animation"
                @click="previewImage(formSettings.success_animation)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>
            <p v-if="apk && apk.success_animation">
              <a :href="getImageLink(apk.success_animation) "  target="_blank">{{  getImageLink(apk.success_animation)  }}</a>
            </p>

      


          <!--  gift_animation -->
          <!-- <v-text-field outlined prepend-inner-icon="mdi-link" type="text" v-model="formSettings.gift_animation" 
          label="Animation Gifty"
            placeholder="Animation Gifty ...." hide-details="" :color="formSettingsErrors && formSettingsErrors.
            gift_animation ? 'red' : 'primary'"
            :class="formSettingsErrors && formSettingsErrors.gift_animation ? 'redBorder' : ''"
            @input="formSettingsErrors && formSettingsErrors.gift_animation ? (formSettingsErrors.gift_animation = '') : false" :loading="isLoading">
          </v-text-field>
          <p class="red--text">
            {{ formSettingsErrors.gift_animation ? formSettingsErrors.gift_animation[0] : "" }}
          </p> -->
          <!-- End gift_animation -->
          <div class="d-flex align-center">
              <v-file-input
                label="Animation Gifty ...."
                accept="image/png, image/jpeg, image/jpg, .svg, .gif, .json"
                show-size
                suffix="Image"
                @change="$event =>  formSettings.gift_animation = $event"
                @focus="
                  formSettingsErrors && formSettingsErrors.gift_animation
                    ? (formSettingsErrors.gift_animation = '')
                    : false
                "
                ref="gift_animation_gifty"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formSettingsErrors.gift_animation"
              />

              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="formSettings.gift_animation"
                @click="previewImage(formSettings.gift_animation)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>
            <p v-if="apk && apk.gift_animation">
              <a :href="getImageLink(apk.gift_animation) "  target="_blank">{{  getImageLink(apk.gift_animation)  }}</a>
            </p>


          <!--  gifty_reveal_animation -->
          <!-- <v-text-field outlined prepend-inner-icon="mdi-link" type="text" v-model="formSettings.gifty_reveal_animation" 
            label="Animation Révélation"
            placeholder="Animation Révélation ...." hide-details="" :color="formSettingsErrors && formSettingsErrors.gifty_reveal_animation ? 'red' : 'primary'"
            :class="formSettingsErrors && formSettingsErrors.gifty_reveal_animation ? 'redBorder' : ''"
            @input="formSettingsErrors && formSettingsErrors.gifty_reveal_animation ? (formSettingsErrors.gifty_reveal_animation = '') : false" :loading="isLoading">
          </v-text-field>
          <p class="red--text">
            {{ formSettingsErrors.gifty_reveal_animation ? formSettingsErrors.gifty_reveal_animation[0] : "" }}
          </p> -->
          <!-- End gifty_reveal_animation -->

          <div class="d-flex align-center">
              <v-file-input
                label="Animation Révélation ...."
                accept="image/png, image/jpeg, image/jpg, .svg, .gif, .json"
                show-size
                suffix="Image"
                @change="$event =>  formSettings.gifty_reveal_animation = $event"
                @focus="
                  formSettingsErrors && formSettingsErrors.gifty_reveal_animation
                    ? (formSettingsErrors.gifty_reveal_animation = '')
                    : false
                "
                ref="gifty_reveal_animation_file"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formSettingsErrors.gifty_reveal_animation"
              />

              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="formSettings.gifty_reveal_animation"
                @click="previewImage(formSettings.gifty_reveal_animation)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>
            <p v-if="apk && apk.gifty_reveal_animation">
              <a :href="getImageLink(apk.gifty_reveal_animation) "  target="_blank">{{  getImageLink(apk.gifty_reveal_animation)  }}</a>
            </p>


          <!--  loading_animation -->
          <!-- <v-text-field outlined prepend-inner-icon="mdi-link" type="text" v-model="formSettings.loading_animation" 
            label="Animation Chargement"
            placeholder="Animation Chargement ...." hide-details="" :color="formSettingsErrors && formSettingsErrors.loading_animation ? 'red' : 'primary'"
            :class="formSettingsErrors && formSettingsErrors.loading_animation ? 'redBorder' : ''"
            @input="formSettingsErrors && formSettingsErrors.loading_animation ? (formSettingsErrors.loading_animation = '') : false" 
            :loading="isLoading">
          </v-text-field>
          <p class="red--text">
            {{ formSettingsErrors.loading_animation ? formSettingsErrors.loading_animation[0] : "" }}
          </p> -->
          <!-- End loading_animation -->
          <div class="d-flex align-center">
              <v-file-input
                label="Animation de Chargement...."
                accept="image/png, image/jpeg, image/jpg, .svg, .gif, .json"
                show-size
                suffix="Image"
                @change="$event =>  formSettings.loading_animation = $event"
                @focus="
                  formSettingsErrors && formSettingsErrors.loading_animation
                    ? (formSettingsErrors.loading_animation = '')
                    : false
                "
                ref="loading_animation_file"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formSettingsErrors.loading_animation"
              />

              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="formSettings.loading_animation"
                @click="previewImage(formSettings.loading_animation)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>
            <p v-if="apk && apk.loading_animation">
              <a :href="getImageLink(apk.loading_animation) " target="_blank">{{  getImageLink(apk.loading_animation)  }}</a>
            </p>
          

          <v-btn type="submit" depressed :loading="btnLoading" large block color="primary" class="rounded-lg mt-3 mb-5">
            Enregister
          </v-btn>
        </form>
      </v-card-text>
    </v-card>

    <MenuItemForm ref="menuItemForm" @refresh="fetchData" />
    <ConfirmDelete ref="deleteMenuItem" @refresh="fetchData" />
  </div>
</template>
<script>
import { HTTP } from "@/http-common";
import MenuItemForm from "./MenuItemForm.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
export default {
  components: { MenuItemForm, ConfirmDelete },
  data() {
    return {
      isLoading: true,
      
      errors: {},
      apk_menu_active: false,
      
      
      btnLoading: false,
      formLoading: false,
      form: {
        menu_link: "",
        menu_items: []
      },
      formErrors: {},
      
      formSettingsErrors: {},
      formSettingsLoading: false,
      formSettings: {
        success_animation: "",
        gift_animation: "",
        gifty_reveal_animation: "",
        loading_animation: ""
      },

      
      menu_items: [],
      apk: null,
    };
  },
  methods: {
    fetchData() {
      console.log("Flexy");
      this.apk = null;
      this.isLoading = true;
      HTTP.get("/v1/settings")
        .then((res) => {
          this.apk_menu_active = res.data.data.apk.apk_menu_active;
          this.form.menu_link = res.data.data.apk.apk_menu_url;
          this.menu_items = res.data.data.apk.menu_items;

          this.apk = res.data.data.apk;

          this.formSettings = {
            // success_animation: res.data.data.apk.success_animation,
            // gift_animation: res.data.data.apk.gift_animation,
            // gifty_reveal_animation: res.data.data.apk.gifty_reveal_animation,
            // loading_animation: res.data.data.apk.loading_animation
          }

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    changeSalepointMenuStatus() {
      this.apk_menu_active = !this.apk_menu_active;

      HTTP.post("/v1/settings/apk_menu_enabled", {
        active: this.apk_menu_active,
      })
        .then((res) => {
          this.isLoading = false;
          this.$successMessage = "Mise a jour avec succès";
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    saveLink() {
      this.btnLoading = true;
      this.formErrors = {};
      this.token = "";

      HTTP.post("/v1/settings/apk_menu_link", this.form)
        .then((res) => {
          this.btnLoading = false;
          this.$successMessage = "Mise a jour avec succès";

        }).catch((err) => {
          this.btnLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.formErrors = err.response.data.errors;
          }

          this.$errorMessage =
            "Une erreur s'est produite veuillez verifier votre formulaire.";
        });
    },

    saveSettings() {
      this.formSettingsLoading = true;
      this.formSettingsErrors = {};
      
      let formData = new FormData();
      Object.keys(this.formSettings).forEach((key) => {
        formData.append(key, this.formSettings[key]);
      });

      HTTP.post("/v1/settings/apk_settings", formData)
        .then((res) => {
          this.formSettingsLoading = false;
          this.$successMessage = "Mise a jour avec succès";
          this.fetchData();

        }).catch((err) => {
          this.formSettingsLoading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            this.formSettingsErrors = err.response.data.errors;
          }

          this.$errorMessage =
            "Une erreur s'est produite veuillez verifier votre formulaire.";
        });
    },
    addMenuItem() {
      this.$refs.menuItemForm.open()
    },
    updateMenuItem(item) {
      this.$refs.menuItemForm.open(item)

    },
    deleteMenuItem(item) {
      this.$refs.deleteMenuItem.open(item);
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style  scoped>
.redBorder>>>fieldset {
  border-color: rgba(250, 0, 33, 0.986);
}
</style>